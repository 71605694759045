<template>
  <v-layout
    wrap
    column
    justify-start
    :class="!bMini ? 'viewLayoutWrapper MiniPadding' : 'viewLayoutWrapper'"
  >
    <!-- Start title -->
    <p class="SectionTitle">Usuarios</p>
    <!-- End title -->
    <!-- Start search component -->
    <search-appointments :mdUp="mdUp" :smUp="smUp"> </search-appointments>
    <!-- End search component -->
    <!-- Start table component -->
    <table-appointments :mdUp="mdUp" :smUp="smUp"> </table-appointments>
    <!--
                <v-layout row justify-center>
        <v-pagination
          color="primary"
          v-model="iCurrentPage"
          :length="iPageNumber"
        ></v-pagination>
      </v-layout>-->
    <!-- End table component -->
  </v-layout>
</template>
<script>
export default {
  components: {
    // import search component
    SearchAppointments: () => import("../../components/views/Users/Search"),
    // import search component
    TableAppointments: () => import("../../components/views/Users/Table"),
  },
  props: {
    // import texts from admin
    texts: Object,
    mdUp: Boolean,
    smUp: Boolean,
    bMini: Boolean,
  },
  computed: {
    // section texts
    sectionTxt() {
      return this.texts.users;
    },
  },
};
</script>
